import React, { useEffect, useState } from "react"
import classNames from "classnames"
import { Form, Formik } from "formik"

import Layout from "../Layout/Layout"
import Message from "elements/Message"
import Container from "../Layout/Container"

import {
  handleResetPassword,
  handleVerifyPasswordResetCode,
} from "./services/authActions"

import FormInput from "../Elements/Form/FormInput"
import { initialValues, validationSchema } from "./utils/resetPasswordSchema"

const ResetPassword = ({ actionCode, continueUrl }) => {
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)

  const handleErrorResetPassword = (error) => {
    setLoading(false)
    setMessage(error?.message || "There was an error updating your password")
  }

  const handleSubmit = (values) => {
    setLoading(true)
    setMessage("")
    handleResetPassword({
      actionCode,
      newPassword: values?.newPassword,
      errorCallback: handleErrorResetPassword,
      successUrl: continueUrl,
    })
  }

  useEffect(() => {
    handleVerifyPasswordResetCode({ actionCode })
  }, [])

  return (
    <Layout
      title="Reset Password"
      subtitle="Enter your new password."
      seoTitle="Reset Password"
    >
      <Container isCentered fullhd={4}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              <FormInput
                type="password"
                name="newPassword"
                label="New Password"
                isRequired
              />
              <FormInput
                type="password"
                name="confirmNewPassword"
                label="Confirm New Password"
                isRequired
              />
              {message && <Message color="warning">{message}</Message>}
              <button
                className={classNames("button is-fullwidth is-primary", {
                  "is-loading": loading,
                })}
                type="submit"
              >
                Save
              </button>
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default ResetPassword
