import React from "react"
import queryString from "query-string"
import ResetPassword from "./ResetPassword"
import { handleVerifyEmail } from "./services/authActions"

const Auth = ({ location }) => {
  const query = location.search
  const parsedQuery = queryString.parse(query)
  const mode = parsedQuery?.mode || ""
  const continueUrl = parsedQuery?.continueUrl || ""
  const oobCode = parsedQuery?.oobCode || ""

  switch (mode) {
    case "resetPassword":
      return <ResetPassword actionCode={oobCode} continueUrl={continueUrl} />
    case "verifyEmail":
      handleVerifyEmail({ actionCode: oobCode, successUrl: continueUrl })
      return null
    default:
      return null
  }
}

export default Auth
